import React from "react"
import Layout from "../components/Layout"
import Footer from "../components/Footer"
import SEO from "../components/SEO"
import DetailSection from "../components/DetailSection"
import NavBar from "../components/NavBar"
import lightbulb from "../images/lightbulb2.jpg"

export default function About(props) {
    return (
        <Layout>
            <SEO
                title="About"
            />
            <NavBar />
            <DetailSection
                img={lightbulb}
                order="order-first"
                intro="Persistently"
                title="Innovating the industry"
                detail="Vending beyond the machine."
                body="Stars and Stripes Vending is an innovative vending company in the Eastern Massachusetts Area. We specialize in open vending and traditional vending machines. All of our services are backed by unwavering customer support and keen attention to detail. We make sure you get the best quality product without the hassle."
                question="Ready to start working with us?"
                showProductSlider="hidden"
            />
            <Footer />

        </Layout>
    )
}
